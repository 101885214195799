import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useChannel, useEventHandler } from "../sockets/";
import { UserContext } from "../providers/UserProvider";
// import SearchModal from "./header/SearchModal";
// import Notifications from "./header/Notifications";
// import Help from "./header/Help";
// import UserMenu from "./header/UserMenu";

function Header({ hideHeader, sidebarOpen, setSidebarOpen }) {
  const { user, logout } = useAuth0();
  const {
    devhookUser,
    setDevhookUser,
    setWebhooks,
    setChannel,
    setInitialSignup,
  } = useContext(UserContext);

  const channel = useChannel(
    `user:${user?.["https://app.devhook.io/uid"]}`,
    undefined,
    (channel, payload) => {
      console.log("Joined channel", payload);
      setDevhookUser(payload.user);
      setWebhooks(payload.webhooks);
      setChannel(channel);
      setInitialSignup(payload.user.initial_signup);
    }
  );

  useEventHandler(channel, "event:new", (message) => {
    console.log("new message", message);
  });

  useEventHandler(channel, "user:update", (message) => {
    setDevhookUser(message);
  });

  return (
    <header className={hideHeader ? "hidden" : "bg-devhook-100 pb-4 z-30"}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex items-center">
            <div className="text-devhook-800 text-lg font-semibold">
              {devhookUser?.email}
            </div>
            {/* Hamburger button */}
            <button
              className="text-puprle-navy-900 hover:text-devhook-800 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <button
              className="text-devhook-800 font-semibold hover:bg-devhook-300 bg-devhook-250 px-4 py-2 rounded-lg text-sm hover:shadow-md"
              onClick={() => logout()}
            >
              Logout
            </button>
            {/* <SearchModal />
            <Notifications />
            <Help /> */}
            {/*  Divider */}
            {/* <hr className="w-px h-6 bg-gray-200 mx-3" />
            <UserMenu /> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
