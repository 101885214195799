import React from "react";

export const UserContext = React.createContext({
  devhookUser: null,
  setDevhookUser: () => {},
  webhooks: null,
  setWebhooks: () => {},
  channel: {},
  setChannel: () => {},
  initialSignup: false,
  setInitialSignup: () => {},
});
