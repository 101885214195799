import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import HelpInput from "../Input/HelpInput";
import { UserContext } from "../../providers/UserProvider";

const CheckoutForm = ({ clientSecret, customerId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { setInitialSignup } = useContext(UserContext);
  const history = useHistory();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
        },
      },
    });

    if (result.error) {
      console.log("[error]", result.error);
      setInitialSignup(false);
      history.push("/");
    } else {
      console.log("[Payment]", result);
      setInitialSignup(false);
      history.push("/");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex mx-auto max-w-5xl flex-col mt-4 gap-y-16 w-full mb-8 border-devhook-500 px-2"
    >
      <div className="text-center">
        <h1 className="text-devhook-800 text-4xl font-bold">Payment Details</h1>
        <h4 className="text-devhook-500 mt-2">
          Finish by entering your card details
        </h4>
      </div>
      <div>
        <div className="px-12 w-full py-8 rounded-lg flex flex-col gap-6 bg-devhook-250 shadow-lg">
          <div className="flex gap-x-6">
            <HelpInput
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
              label="First Name"
              required
            />
            <HelpInput
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
              label="Last Name"
              required
            />
          </div>
          <div className="flex-grow">
            <label
              for="card"
              className="block text-md pl-2 mb-1 font-medium text-devhook-700"
            >
              Card
            </label>
            <div className="flex gap-x-6">
              <CardElement
                id="card"
                options={{
                  style: {
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                className="text-devhook-700 py-3 bg-white block w-full pl-4 pr-8 sm:text-sm border border-devhook-250 rounded-lg shadow-md"
              />
              <button
                className=" bg-devhook-500 px-6 text-white text-md rounded-lg shadow-md hover:shadow-xl"
                type="submit"
                disabled={!stripe}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
