import React from "react";
import { NavLink, Link } from "react-router-dom";

export default function Sidebar({ hideSidebar, sidebarOpen, setSidebarOpen }) {
  return (
    <div
      className={
        hideSidebar
          ? "hidden"
          : "h-screen w-72 flex flex-col justify-start  bg-devhook-100 border-r border-devhook-250"
      }
    >
      <Link exact to="/" className="block h-auto mb-36">
        <svg
          width="199.99999999999997"
          height="34.26941974334401"
          viewBox="0 0 199.99999999999997 34.26941974334401"
          className="mx-auto mt-8 sulphur self-center justify-center text-5xl text-center"
        >
          <defs id="SvgjsDefs2131"></defs>
          <g
            id="SvgjsG2132"
            featurekey="j5pGhi-0"
            transform="matrix(2.1939448080737676,0,0,2.1939448080737676,-1.7551552187663695,-9.653355272446644)"
            fill="#406BA0"
          >
            <path d="M0.8 14.18 c0 3.14 2.66 5.84 6.04 5.84 c1 0 1.94 -0.2 2.8 -0.62 l0 0.6 l3.24 0 l0 -0.18 c0 -1.88 0.02 -3.76 0.02 -5.64 c0 -3.18 -0.02 -6.38 -0.02 -9.56 l0 -0.2 l-3.18 0 l0 4.6 c-0.88 -0.44 -1.84 -0.66 -2.86 -0.66 c-3.38 0 -6.04 2.7 -6.04 5.82 z M8.84 16.16 c-0.54 0.54 -1.2 0.82 -2 0.82 c-1.58 0 -2.8 -1.32 -2.8 -2.8 c0 -1.46 1.22 -2.78 2.8 -2.78 c0.8 0 1.46 0.3 2 0.82 c0.4 0.4 0.8 1.1 0.8 1.62 l0 0.7 c0 0.52 -0.4 1.24 -0.8 1.62 z M14.500000000000002 14.18 c0 -3.08 2.62 -5.76 5.98 -5.76 c3.34 0 5.96 2.68 5.96 5.76 c0 0.42 -0.04 0.82 -0.12 1.2 l-8.34 0.02 c0.44 0.9 1.36 1.54 2.5 1.54 c0.78 0 1.48 -0.32 1.96 -0.8 l3.64 0 c-0.82 2.18 -3 3.82 -5.6 3.82 c-3.36 0 -5.98 -2.6 -5.98 -5.7 l0 -0.08 z M18.080000000000002 12.8 l-0.1 0.28 l5.06 0 l-0.16 -0.28 c-0.48 -0.8 -1.34 -1.36 -2.4 -1.36 s-1.94 0.56 -2.4 1.36 z M26.580000000000002 8.44 l4.7 11.54 l3.12 0 l4.72 -11.54 l-3.58 0 l-2.7 7.16 l-2.68 -7.16 l-3.58 0 z M40.32 4.58 c0 5 -0.02 10.24 -0.02 15.24 l0 0.18 l3.24 0 l0 -5.92 c0 -1.44 1.26 -2.7 2.8 -2.7 c1.48 0 2.64 1.14 2.8 2.5 c0.02 0.2 0 0.42 0 0.62 l0.02 1.46 l0 4 l3.24 0.04 l0 -6.02 c0 -1.46 -0.74 -2.9 -1.8 -3.92 c-1.16 -1.12 -2.58 -1.72 -4.26 -1.72 c-1 0 -1.96 0.22 -2.84 0.66 l0 -4.6 l-3.18 0 l0 0.18 z M54 14.18 c0 3.1 2.62 5.78 5.98 5.78 c3.34 0 5.96 -2.68 5.96 -5.78 c0 -3.08 -2.62 -5.76 -5.96 -5.76 c-3.36 0 -5.98 2.68 -5.98 5.76 z M62.74 14.18 c0 1.5 -1.16 2.76 -2.76 2.76 s-2.78 -1.28 -2.78 -2.76 c0 -1.46 1.18 -2.74 2.78 -2.74 s2.76 1.26 2.76 2.74 z M67.54 14.18 c0 3.1 2.62 5.78 5.98 5.78 c3.34 0 5.96 -2.68 5.96 -5.78 c0 -3.08 -2.62 -5.76 -5.96 -5.76 c-3.36 0 -5.98 2.68 -5.98 5.76 z M76.28 14.18 c0 1.5 -1.16 2.76 -2.76 2.76 s-2.78 -1.28 -2.78 -2.76 c0 -1.46 1.18 -2.74 2.78 -2.74 s2.76 1.26 2.76 2.74 z M81.08000000000001 20.02 l3.24 0 l0 -3.4 l0.52 -0.58 l2.92 3.96 l3.96 0 l-4.7 -6.34 l4.94 -5.26 l-4.12 0 l-3.56 3.86 l0 -7.86 l-3.18 0 z"></path>
          </g>
        </svg>
      </Link>
      <div className="flex flex-col justify-start px-8 text-devhook-700 font-semibold text-lg space-y-4">
        <NavLink
          exact
          to="/profile"
          className="inline-block flex items-center justify-start py-3 px-4 hover:shadow-md hover:bg-devhook-500 rounded-lg text-devhook-700 hover:text-devhook-100"
        >
          <svg
            className="inline-block mr-4 stroke-current"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-reactroot=""
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
              fill="none"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
              fill="none"
              d="M19.33 18.79C18.09 15.97 15.28 14 12 14C8.72 14 5.91 15.97 4.67 18.79C6.5 20.76 9.1 22 12 22C14.9 22 17.5 20.76 19.33 18.79Z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
              fill="none"
              d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
            ></path>
          </svg>
          Profile
        </NavLink>
        <NavLink
          exact
          to="/"
          className="inline-block flex items-center justify-start py-3 px-4 hover:bg-devhook-500 rounded-lg text-devhook-700 hover:text-devhook-100"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-reactroot=""
            className="inline-block mr-4 fill-current"
          >
            <path
              d="M6 6C6 6.55228 5.55228 7 5 7L3 7C2.44772 7 2 6.55228 2 6C2 5.44771 2.44772 5 3 5L5 5C5.55229 5 6 5.44772 6 6Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
            <path
              d="M22 6C22 6.55228 21.5523 7 21 7L9 7C8.44772 7 8 6.55228 8 6C8 5.44771 8.44772 5 9 5L21 5C21.5523 5 22 5.44772 22 6Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
            <path
              d="M6 12C6 12.5523 5.55228 13 5 13L3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L5 11C5.55229 11 6 11.4477 6 12Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
            <path
              d="M22 12C22 12.5523 21.5523 13 21 13L9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11L21 11C21.5523 11 22 11.4477 22 12Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
            <path
              d="M6 18C6 18.5523 5.55228 19 5 19L3 19C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17L5 17C5.55229 17 6 17.4477 6 18Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
            <path
              d="M22 18C22 18.5523 21.5523 19 21 19L9 19C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17L21 17C21.5523 17 22 17.4477 22 18Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
          </svg>{" "}
          Webhooks
        </NavLink>
      </div>
      <div></div>
    </div>
  );
}
