import React from "react";

export default function StatusCircle({ color }) {
  return (
    <svg
      className="h-4 leading-tight ml-2 inline-block"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter id="glow">
        <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
        <feMerge>
          <feMergeNode in="coloredBlur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke={color}
        fill={color}
        // style={{ filter: "url(#glow)" }}
      />
    </svg>
  );
}
