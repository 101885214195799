import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";

import { API_HOST } from "./env";
import { UserContext } from "./providers/UserProvider";
import plans from "./data/plans";
import CheckoutForm from "./components/Stripe/Card";

function CheckCircleFilled() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        dataReactroot=""
      >
        <path
          fill="#3A6292"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          undefined="1"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke="white"
          d="M7 12L10.5 15.5L17 8.5"
        ></path>
      </svg>
    </>
  );
}

const stripePromise = loadStripe(
  "pk_test_51JHESWD1LkeKcERFy2dSG09aEwSJBGnj1ce1qVFcYBICa2XBqRgEMkf0z22ZpV6C4lL7fZIGLm6hvDywoBhJT9SM00DCoiotQW"
);

const FinishSignup = () => {
  const [checked, setChecked] = useState(plans[1]);
  const [monthly, setMonthly] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [step, setStep] = useState(1);
  const { initialSignup, setInitialSignup, setDevhookUser } = useContext(UserContext);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {}, [initialSignup]);

  async function selectSubscription() {
    var priceId = monthly ? checked.monthlyPriceId : checked.yearlyPriceId;
    var token = await getAccessTokenSilently();
    if (!priceId) {
      var {
        data: user
      } = await axios.post(
        `${API_HOST}/subscription/new`,
        { priceId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDevhookUser(user);
      setInitialSignup(false);
      history.push("/")
    } else {
      var {
        data: { clientSecret: client_secret },
      } = await axios.post(
        `${API_HOST}/subscription/new`,
        { priceId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setClientSecret(client_secret);
      setStep(2);
    }
  }

  function discount(plan) {
    if (monthly) {
      return "";
    } else {
      const total = plan.monthlyPrice * 12;
      return Math.floor(((total - plan.yearlyPrice) / total) * 100);
    }
  }

  function steps() {
    switch (step) {
      case 1:
        return (
          <div className="mx-auto md:px-12 lg:pt-0 pt-8 max-w-screen-xl min-h-screen justify-center flex flex-col justify-around text-center">
            <div className="">
              <h1 className="text-devhook-800 text-4xl font-bold">
                Select your plan
              </h1>
              <h4 className="text-devhook-500 mt-2">
                Simple transparent pricing
              </h4>
            </div>
            <div className="px-12 h-96 max-h-full flex gap-x-6">
              <div className="h-full w-1/2 flex flex-col gap-y-8 bg-devhook-200 rounded-xl px-12 py-6">
                <div className="text-devhook-600 font-bold text-xl flex justify-between items-center">
                  <span>Features</span>
                  <label
                    className="text-sm inline-block cursor-pointer relative flex bg-devhook-100 rounded-lg"
                    style={{
                      minWidth: "150px",
                      height: "38px",
                      width: "170px",
                    }}
                  >
                    <input
                      className="absolute top-0 left-0 opacity-0 w-full align-middle"
                      type="checkbox"
                      checked={monthly}
                      onChange={(e) => setMonthly(e.target.checked)}
                    ></input>
                    <span
                      className={
                        monthly
                          ? "transition ease-in bg-devhook-500 text-white rounded py-1 absolute w-1/2 text-center"
                          : "transition ease-in absolute w-1/2 text-center py-1 rounded"
                      }
                      style={{ left: "5px", top: "5px" }}
                    >
                      Monthly
                    </span>
                    <span
                      className={
                        !monthly
                          ? "transition ease-linear duration-150 bg-devhook-500 text-white rounded py-1 absolute w-1/2 text-center"
                          : "transition ease-linear duration-150 absolute w-1/2 text-center py-1 rounded"
                      }
                      style={{ right: "5px", top: "5px" }}
                    >
                      Yearly
                    </span>
                  </label>
                </div>
                <div className="flex flex-col gap-y-6 transition ease-linear duration-150">
                  {listFeatures()}
                </div>
              </div>
              <fieldset
                value={checked}
                onChange={(event) => setChecked(plans[event.target.value])}
                className="h-full w-1/2 flex flex-col gap-y-8 justify-between"
              >
                {listPlans()}
              </fieldset>
            </div>
            <div className="flex justify-end pt-8 border-t-2 w-full border-devhook-250 px-12">
              <button
                onClick={() => {
                  selectSubscription();
                }}
                className="px-6 py-3 bg-devhook-600 text-white rounded-lg"
              >
                Choose Plan
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <Elements stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        );
      default:
        return null;
    }
  }

  function listFeatures() {
    return checked.features.map((feature) => {
      return (
        <div className="flex justify-between">
          <span>{feature}</span>
          <CheckCircleFilled />
        </div>
      );
    });
  }

  function listPlans() {
    return plans.map((plan) => {
      if (plan.name === checked.name) {
        return (
          <label
            className="transition ease-linear duration-150 flex-grow bg-devhook-600 cursor-pointer h-24 font-semibold border rounded-xl px-8 text-white cursor-pointer text-xl w-full flex justify-between justify-items-start items-center"
            htmlFor={plan.name}
          >
            <input
              value={plan}
              type="radio"
              id={plan.name}
              name="plans"
              checked
              className="hidden"
            />
            <div className="flex flex-col gap-y-2">
              <div className="self-start">{plan.name}</div>
              {monthly || !plan.yearlyHelpText ? (
                ""
              ) : (
                <div className="bg-devhook-200 text-xs text-devhook-600 px-1 py-1.5 rounded-full">
                  Save {discount(plan)}%
                </div>
              )}
            </div>
            <span className="text-3xl flex items-center">
              <span>${monthly ? plan.monthlyPrice : plan.yearlyPrice}</span>
              <span className="ml-1 hover:text-devhook-white text-sm">
                {monthly ? "/ month" : "/ year"}
              </span>
            </span>
          </label>
        );
      } else {
        return (
          <label
            className="transition ease-linear duration-150 flex-grow bg-devhook-100 cursor-pointer h-24 font-semibold border rounded-xl text-devhook-600 px-8 border-devhook-250 shadow-sm hover:shadow-lg cursor-pointer text-xl w-full flex justify-between justify-items-start items-center"
            htmlFor={plan.name}
          >
            <input
              type="radio"
              id={plan.name}
              name="plans"
              value={plan.id}
              className="hidden"
            />
            <div className="flex flex-col gap-y-2">
              <div className="self-start">{plan.name}</div>
              {monthly || !plan.yearlyHelpText ? (
                ""
              ) : (
                <div className="bg-devhook-500 text-xs text-white px-1 py-1.5 rounded-full">
                  Save {discount(plan)}%
                </div>
              )}
            </div>
            <span className="text-3xl flex items-center">
              <span>${monthly ? plan.monthlyPrice : plan.yearlyPrice}</span>
              <span className="ml-1 hover:text-devhook-white text-sm">
                {monthly ? "/ month" : "/ year"}
              </span>
            </span>
          </label>
        );
      }
    });
  }

  return <div className="bg-devhook-100">{steps()}</div>;
};

export default FinishSignup;
