import React, { useContext } from "react";
import { Link } from "react-router-dom";

import WebhookListItem from "./components/Webhooks/WebhookListItem";
import { UserContext } from "./providers/UserProvider";
import Title from "./components/Title";
import BeatLoader from "react-spinners/BeatLoader";

export default function WebhookList() {
  const { devhookUser, webhooks } = useContext(UserContext);

  function CreateButton() {
    if (!webhooks) {
      return null;
    }
    if (
      (devhookUser.subscription_name === "free" && webhooks.length > 0) ||
      (devhookUser.subscription_name === "developer" && webhooks.length >= 10)
    ) {
      return null;
    } else {
      return (
        <Link
          to="/webhooks/new"
          className="group px-4 py-2 mr-4 text-white bg-devhook-500 hover:bg-devhook-700 rounded-lg self-center flex gap-x-1 font-semibold text-sm hover:shadow-md"
        >
          <svg
            className="h-5 self-center"
            viewBox="0 0 24 24"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
            data-reactroot=""
          >
            <path
              fill="#ffffff"
              d="M10.5 4.5C10.5 3.66772 11.1677 3 12 3C12.8323 3 13.5 3.66772 13.5 4.5V19.5C13.5 20.3323 12.8323 21 12 21C11.1677 21 10.5 20.3323 10.5 19.5V4.5Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
            <path
              fill="#ffffff"
              d="M3 12C3 11.1677 3.66772 10.5 4.5 10.5H19.5C20.3323 10.5 21 11.1677 21 12C21 12.8323 20.3323 13.5 19.5 13.5H4.5C3.66772 13.5 3 12.8323 3 12Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              undefined="1"
            ></path>
          </svg>
          Create
        </Link>
      );
    }
  }

  function listWebhooks() {
    console.log(webhooks);
    if (!webhooks) {
      return (
        <div className="bg-devhook-250 px-4 h-18 text-center text-devhook-600 py-6 font-semibold rounded-lg justify-center">
          <BeatLoader color="#3A6292" />
        </div>
      );
    } else if (webhooks.length) {
      return webhooks.map((webhook) => {
        return <WebhookListItem key={webhook.uid} webhook={webhook} />;
      });
    } else {
      return (
        <div className="bg-devhook-250 px-4 h-18 text-center text-devhook-600 py-4 font-semibold rounded-lg justify-center">
          No webhooks found!{" "}
          <Link
            to="/webhooks/new"
            className="bg-devhook-500 text-devhook-100 px-2 py-1 font-semibold rounded hover:shadow"
          >
            Create
          </Link>{" "}
          your first now!
        </div>
      );
    }
  }

  return (
    <>
      <div className="container">
        <div className="flex justify-between mb-4">
          <Title title="Webhook List" />
          <CreateButton />
        </div>
        <div className="rounded-lg bg-devhook-250 shadow-lg">
          {listWebhooks()}
        </div>
      </div>
    </>
  );
}
