export default function Button({ onClick, type, children }) {
  function classes() {
    if (type === "primary") {
      return "px-4 py-2 mr-4 text-sm font-semibold text-white bg-devhook-500 hover:bg-devhook-700 rounded-lg self-center hover:shadow-md";
    } else {
      return "px-4 py-2 mr-4 text-sm font-semibold text-devhook-700 bg-devhook-250 hover:bg-devhook-300 rounded-lg self-center hover:shadow-md";
    }
  }
  return (
    <button className={classes()} onClick={(e) => onClick(e)}>
      {children}
    </button>
  );
}
