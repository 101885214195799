import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "./providers/UserProvider";
import { sendMessage } from "./sockets/";

export default function Profile() {
  const { channel, devhookUser, setDevhookUser } = useContext(UserContext);
  const [editableUser, setEditableUser] = useState(devhookUser);

  useEffect(() => {
    setEditableUser(devhookUser);
  }, [devhookUser, setEditableUser]);

  function buildGreeting() {
    if (devhookUser.first_name) {
      return `Hello ${devhookUser.first_name}`;
    } else {
      return "Howdy!";
    }
  }

  function determineRequestCount() {
    switch (devhookUser.subscription_name) {
      case "free":
        return `${devhookUser.request_count} / 1000`;
      default:
        return `${devhookUser.request_count} / unlimited`;
    }
  }

  function updateUser(e) {
      e.preventDefault();
      sendMessage(channel, "user:update", editableUser).then((response) => {
        setDevhookUser(response);
      });
  }

  return (
    <>
      <div className="container">
        <div class="flex justify-between mb-8">
          <h3 className="text-devhook-700 pl-4 font-semibold  text-3xl self-center">
            {buildGreeting()}{" "}
          </h3>
        </div>
        <div className="">
          <form className="">
            <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl self-center">
              Personal Info
            </h3>
            <div className="px-6 py-6 shadow-md bg-devhook-250 rounded-lg mb-6 flex gap-x-6 justify-start w-full">
              <div className="flex-grow">
                <label
                  for="first_name"
                  className="block text-md pl-2 font-medium text-devhook-700"
                >
                  First Name
                </label>
                <div className="mt-1 relative rounded-lg shadow-md">
                  <input
                    type="text"
                    id="first_name"
                    className="focus:ring-devhook-500 focus:border-devhook-500 block w-full pl-4 pr-8 sm:text-sm border border-devhook-250 rounded-lg"
                    value={editableUser.first_name}
                    onChange={(e) => {
                      setEditableUser({
                        ...editableUser,
                        first_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow">
                <label
                  for="last_name"
                  className="block text-md pl-2 font-medium text-devhook-700"
                >
                  Last Name
                </label>
                <div className="mt-1 relative rounded-lg shadow-md">
                  <input
                    type="text"
                    id="last_name"
                    className="focus:ring-devhook-500 focus:border-devhook-500 block w-full pl-4 pr-8 sm:text-sm border border-devhook-250 rounded-lg"
                    value={editableUser.last_name}
                    onChange={(e) => {
                      setEditableUser({
                        ...editableUser,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <button onClick={(e) => updateUser(e)} className="self-end py-2 text-sm font-semibold px-4 bg-devhook-700 text-devhook-100 rounded-lg hover:shadow-md">
                Save
              </button>
            </div>
            <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl self-center">
              Plan Details
            </h3>
            <div className="px-6 py-6 mb-6 shadow-md bg-devhook-250 text-devhook-800 rounded-lg flex gap-x-6 justify-start w-full">
              <h3 className="font-semibold">
                Current Tier: {devhookUser.subscription_name?.toUpperCase()}
              </h3>
            </div>
            <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl self-center">
              Usage Details
            </h3>
            <div className="px-6 py-6 shadow-md bg-devhook-250 text-devhook-800 rounded-lg flex gap-x-6 justify-start w-full">
              <h3 className="font-semibold">
                Request Count: {determineRequestCount()}
              </h3>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
