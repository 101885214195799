import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { hri } from "human-readable-ids";
import { useChannel, useEventHandler } from "./sockets";
import SelectedEvent from "./components/Monitor/SelectedEvent";
import StatusCircle from "./components/StatusCircle";
import { API_HOST } from "./env";

function Copy() {
  return (
    <>
      <svg
        className="inline mr-1"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#1d3149"
          fill="none"
          d="M8 6V3C8 2.45 8.45 2 9 2H20C20.55 2 21 2.45 21 3V17C21 17.55 20.55 18 20 18H16V7C16 6.45 15.55 6 15 6H8Z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#1d3149"
          fill="none"
          d="M15 22H4C3.45 22 3 21.55 3 21V7C3 6.45 3.45 6 4 6H15C15.55 6 16 6.45 16 7V21C16 21.55 15.55 22 15 22Z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
      </svg>
    </>
  );
}

export default function Monitor() {
  const { webhook_uid } = useParams();
  const [webhookEvents, setWebhookEvents] = useState([]);
  const [webhook, setWebhook] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const connectedColor = "#2cc956";

  const channel = useChannel(
    `webhooks:${webhook_uid}`,
    undefined,
    (channel, { webhook }) => {
      setWebhook(webhook);
    }
  );

  useEventHandler(channel, "event:new", (message) => {
    const id = hri.random();
    message.metadata.id = id;
    console.log(message.body);
    forwardEvent(message);
    setWebhookEvents((webhookEvents) => [...webhookEvents, message]);
  });

  function forwardEvent(message) {
    axios
      .post(webhook.destination, message.body, {
        headers: message.headers,
      })
      .then((res) => {
        Object.assign(message, {
          result: {
            status: res.status,
            statusText: res.statusText,
            responseData: res.data,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          Object.assign(message, {
            result: {
              status: err.response.status,
              statusText: err.response.statusText,
              responseData: err.response.data,
            },
          });
        } else {
          Object.assign(message, {
            result: {
              error: JSON.parse(err).message,
            },
          });
        }
      })
      .then(() => {
        setWebhookEvents((prevState) => {
          var newState = JSON.parse(JSON.stringify(prevState));
          var index = newState.findIndex(
            (x) => x.metadata.id === message.metadata.id
          );
          newState[index] = message;
          return newState;
        });
      });
  }

  function displayEvent() {
    if (!selectedEvent) {
      return (
        <div className="text-devhook-800 text-center h-36 flex justify-center items-center font-bold text-lg">
          No Event Selected. You will need to edit your local CORS setting on your server to allow forwarding events from the app. Please consider using the <a href="https://www.npmjs.com/package/devhook-cli">CLI</a> for a smoother experience.
        </div>
      );
    } else {
      return <SelectedEvent event={selectedEvent} />;
    }
  }

  function formatTimestamp(timestamp) {
    const datetime = new Date(timestamp);
    return datetime.toLocaleString("en-US");
  }

  function listEvents() {
    if (webhookEvents.length) {
      return webhookEvents.map((event) => {
        return (
          <div
            onClick={() => setSelectedEvent(event)}
            key={event.metadata.id}
            className="bg-devhook-250 hover:shadow-sm hover:bg-devhook-300 hover:text-devhook-100 cursor-pointer mx-2 px-2 text-devhook-700 py-2 rounded-lg justify-center"
          >
            <div className="mb-2 overflow-x-hidden">
              <span className="bg-devhook-500 text-white px-2 text-md py-1 mr-2 rounded">
                {event.metadata.method}
              </span>
              {event.metadata.request_ip}
            </div>
            <div className="text-sm">
              {formatTimestamp(event.metadata.timestamp)}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="bg-devhook-250 px-4 h-18 mt-8 text-center self-center text-devhook-900 py-4 font-semibold rounded-lg justify-center">
          No Events Found.
        </div>
      );
    }
  }

  return (
    <>
      <div className="max-h-full h-full flex-initial flex">
        {/* Event list */}
        <div className="max-h-full min-h-full shadow-md w-80 overflow-y-auto py-4 bg-devhook-250 rounded-xl border-r border-devhook-250">
          <h3 className="text-devhook-700 pl-6 items-center pb-4 flex inline-block font-bold text-xl overflow-y-auto">
            Events
            <StatusCircle color={connectedColor} />
          </h3>
          {listEvents()}
        </div>
        <div className="max-h-full min-h-full w-full">
          <div className="flex items-end flex-col gap-y-4 pl-4 mb-4">
            <p className="text-devhook-900">
              Destination:{" "}
              <span
                className="font-semibold py-2 px-2 bg-devhook-250 cursor-pointer rounded"
                onClick={() => {
                  navigator.clipboard.writeText(webhook.destination);
                }}
              >
                <Copy />
                {webhook.destination}
              </span>
            </p>
            <p className="text-devhook-900 whitespace-nowrap">
              Listening at{" "}
              <span
                className="font-semibold py-2 px-2 bg-devhook-250 cursor-pointer rounded whitespace-nowrap"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://api.devhook.io/webhooks/${webhook_uid}`
                  );
                }}
              >
                <Copy />
                {`${API_HOST}/webhooks/${webhook_uid}`}
              </span>
            </p>
          </div>
          <div className="pl-4 py-4 overflow-y-auto">{displayEvent()}</div>
        </div>
      </div>
    </>
  );
}
