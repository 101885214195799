import React from "react";

import HelpInput from "../Input/HelpInput";

export default function DeveloperForm({ webhook, setWebhook }) {
  return (
    <form className="flex flex-col justify-between mt-4 w-full mb-8 border-devhook-500 px-2">
      <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl">
        Basic Info
      </h3>
      <div className="px-6 py-6 shadow-md bg-devhook-250 rounded-lg mb-6 flex gap-x-6 justify-start w-full">
        <HelpInput
          onChange={(e) =>
            setWebhook({ ...webhook, human_name: e.target.value })
          }
          value={webhook?.human_name}
          label="Name"
          helpText="A human friendly name"
          required="true"
        />
      </div>
      <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl">
        Allowed Origins
      </h3>
      <div className="px-6 py-6 shadow-md bg-devhook-250 rounded-lg mb-6 flex gap-x-6 justify-start w-full">
        <div className="flex-grow">
          <label
            for="name"
            className="block text-md pl-2 font-medium text-devhook-700"
          >
            Origins
          </label>
          <div className="mt-1 max-w-3xl relative">
            <textarea
              id="origins"
              rows="3"
              className="focus:ring-devhook-500 flex-grow focus:border-devhook-500 block w-full pl-4 pr-8 sm:text-sm border border-devhook-250 rounded-lg shadow-md"
              value={webhook?.allowed_origins}
              onChange={(e) =>
                setWebhook({ ...webhook, allowed_origins: e.target.value })
              }
            />
            <div className="text-sm mt-1 ml-2 text-devhook-800">
              A whitespace seperated list of allowed origins
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl">
        Usage Info
      </h3>
      <div className="px-6 py-6 mb-6 shadow-md bg-devhook-250 text-devhook-800 rounded-lg flex gap-x-6 justify-start w-full">
        <HelpInput
          onChange={(e) =>
            setWebhook({ ...webhook, destination: e.target.value })
          }
          value={webhook?.destination}
          label="Destination"
          helpText="Where webhooks will foward to"
          required="true"
        />
      </div>
      <div className="flex justify-end pt-8 w-full"></div>
    </form>
  );
}
