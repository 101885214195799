import React from "react";
import { Link } from "react-router-dom";

export default function WebhookListItem({ webhook }) {
  return (
    <div className="group transition duration-150 ease-in-out hover:bg-devhook-500 bg-devhook-250 flex last:rounded-b-lg first:rounded-t-lg even:bg-devhook-300 justify-between items-center text-devhook-800 hover:text-white px-6 py-4">
      <div className="flex flex-col align-start">
        <div className="text-lg font-semibold">{webhook.human_name}</div>
        <div className="transition duration-150 ease-in-out group-hover:text-white text-devhook-800">
          {webhook.destination}
        </div>
      </div>
      <div>
        <Link
          to={`/${webhook.uid}/monitor`}
          className="inline-block mr-2 transition duration-150 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 group-hover:text-white text-devhook-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </Link>
        <Link
          to={`/${webhook.uid}/edit`}
          className="inline-block ml-2 transition duration-150 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 group-hover:text-white text-devhook-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}
