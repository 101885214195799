import React from "react";
import StatusCircle from "../StatusCircle";

export default function SelectedEvent({ event }) {
  function listValues(list) {
    if (!Object.keys(list).length) {
      return (
        <div className="bg-devhook-250 text-devhook-800 font-semibold text-center rounded-lg py-4">
          No Items
        </div>
      );
    } else {
      return Object.keys(list).map(function (key, index) {
        return (
          <div
            key={key}
            className="flex items-center justify-between last:rounded-b-lg even:bg-devhook-300 px-4 py-2"
          >
            <span>{key}</span>
            <span className="px-3 py-2 text-sm bg-devhook-200 text-devhook-900 rounded-lg">
              <pre>
                <code>{JSON.stringify(list[key])}</code>
              </pre>
            </span>
          </div>
        );
      });
    }
  }

  function displayResult() {
    if (event.result.error) {
      return (
        <div className="px-3 py-2">
          The following error occured:{" "}
          <span className="font-bold">{event.result.error}</span>
        </div>
      );
    } else {
      return <div>{listValues(event.result)}</div>;
    }
  }

  function resultColor() {
    if (event.result.error || event.result.status > 399) {
      return "#E87461";
    } else if (event.result.status > 299 && event.result.status < 400) {
      return "#EEB902";
    } else {
      return "#2cc956";
    }
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="pl-4 pb-2 font-semibold text-lg flex items-center text-devhook-800">
          Result
          <StatusCircle color={resultColor()} />
        </h3>
        <div className="flex flex-col shadow-md w-full bg-devhook-250 rounded-lg">
          {displayResult()}
        </div>
      </div>
      <div>
        <h3 className="pl-4 pb-2 font-semibold text-lg text-devhook-800">
          Headers
        </h3>
        <div className="flex flex-col shadow-md w-full bg-devhook-250 rounded-lg">
          {listValues(event.headers)}
        </div>
      </div>
      <div>
        <h3 className="pl-4 pb-2 font-semibold text-lg text-devhook-800">
          Cookies
        </h3>
        <div className="flex flex-col shadow-md w-full bg-devhook-250 rounded-lg">
          {listValues(event.cookies)}
        </div>
      </div>
      <div>
        <h3 className="pl-4 pb-2 font-semibold text-lg text-devhook-800">
          Body
        </h3>
        <pre className="w-full bg-devhook-250 shadow-md text-devhook-800 px-4 py-2 rounded-lg">
          <code>{JSON.stringify(event.body, null, 4)}</code>
        </pre>
      </div>
    </div>
  );
}
