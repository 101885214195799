import React from "react";

import HelpInput from "../Input/HelpInput";

export default function FreeForm({ webhook, setWebhook }) {
  return (
    <form className="flex flex-col justify-between mt-4 w-full mb-8 border-devhook-500 px-2">
      <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl">
        Basic Info
      </h3>
      <div className="px-6 py-6 shadow-md bg-devhook-250 rounded-lg mb-6 flex gap-x-6 justify-start w-full">
        <HelpInput
          onChange={(e) =>
            setWebhook({ ...webhook, human_name: e.target.value })
          }
          value={webhook?.human_name}
          label="Name"
          helpText="A human friendly name"
          required="true"
        />
      </div>
      <h3 className="text-devhook-600 pl-4 mb-2 font-semibold  text-xl">
        Usage Info
      </h3>
      <div className="px-6 py-6 mb-6 shadow-md bg-devhook-250 text-devhook-800 rounded-lg flex gap-x-6 justify-start w-full">
        <HelpInput
          onChange={(e) =>
            setWebhook({ ...webhook, destination: e.target.value })
          }
          value={webhook?.destination}
          label="Destination"
          helpText="Where webhooks will foward to"
          required="true"
        />
      </div>
      <div className="flex justify-end pt-8 w-full"></div>
    </form>
  );
}
