import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { UserContext } from "./providers/UserProvider";
import { sendMessage } from "./sockets/";
import Button from "./components/Button";
import Title from "./components/Title";
import {
  FreeForm,
  DeveloperForm,
  ProfessionalForm,
} from "./components/WebhookForms";

export default function EditWebhook() {
  const { webhook_uid } = useParams();
  const { devhookUser, webhooks, channel, setWebhooks } =
    useContext(UserContext);
  const [webhook, setWebhook] = useState(() => {
    return webhooks?.find((hook) => {
      return hook.uid === webhook_uid;
    });
  });
  const history = useHistory();

  function editWebhook(e) {
    e.preventDefault();
    sendMessage(channel, "webhooks:update", webhook).then((response) => {
      setWebhooks(response);
      history.push("/");
    });
  }

  function cancelChange(e) {
    e.preventDefault();
    history.push("/");
  }

  function displayForm() {
    switch (devhookUser?.subscription_name) {
      case "professional":
        return <ProfessionalForm webhook={webhook} setWebhook={setWebhook} />;
      case "developer":
        return <DeveloperForm webhook={webhook} setWebhook={setWebhook} />;
      default:
        return <FreeForm webhook={webhook} setWebhook={setWebhook} />;
    }
  }

  return (
    <div className="container">
      <div class="flex justify-between mb-8">
        <Title title="Edit Webhook" />
        <div className="flex gap-x-1">
          <Button type="secondary" onClick={(e) => cancelChange(e)}>
            Cancel
          </Button>
          <Button type="primary" onClick={(e) => editWebhook(e)}>
            Save
          </Button>
        </div>
      </div>
      {displayForm()}
    </div>
  );
}
