import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "./providers/UserProvider";
import { sendMessage } from "./sockets/";
import {
  DeveloperForm,
  FreeForm,
  ProfessionalForm,
} from "./components/WebhookForms";

import Title from "./components/Title";

export default function NewWebhook() {
  const [webhook, setWebhook] = useState({
    human_name: "",
    destination: "",
    response: {},
    always_accept: false,
  });
  const { devhookUser, channel, setWebhooks } = useContext(UserContext);
  const history = useHistory();

  function createWebhook(e) {
    e.preventDefault();
    sendMessage(channel, "webhooks:new", webhook).then((response) => {
      setWebhooks(response);
      history.push("/");
    });
  }

  function displayForm() {
    switch (devhookUser?.subscription_name) {
      case "professional":
        return <ProfessionalForm webhook={webhook} setWebhook={setWebhook} />;
      case "developer":
        return <DeveloperForm webhook={webhook} setWebhook={setWebhook} />;
      default:
        return <FreeForm webhook={webhook} setWebhook={setWebhook} />;
    }
  }

  return (
    <div className="container">
      <div class="flex justify-between mb-8">
        <Title title="Create Webhook" />
        <button
          type="submit"
          className="px-3 py-2 mr-4 text-white bg-devhook-500 hover:bg-devhook-700 rounded-lg self-center"
          onClick={(e) => createWebhook(e)}
        >
          Save
        </button>
      </div>
      {displayForm()}
    </div>
  );
}
