import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import queryString from "query-string";

import Monitor from "./Monitor";
import "./App.css";
import WebhookList from "./WebhookList";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import NewWebhook from "./NewWebhook";
import EditWebhook from "./EditWebhook";
import Profile from "./Profile";
import FinishSignup from "./FinishSignup";
import { UserContext } from "./providers/UserProvider";
import Auth0ProviderWithHistory from "./providers/Auth0Provider";
import { SocketProvider } from "./sockets/SocketContext";
import { SOCKET_URL } from "./env/index";

const ProtectedRoute = ({ component, onRedirecting, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, { onRedirecting })}
    {...args}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  onRedirecting: PropTypes.func,
};

ProtectedRoute.defaultProps = {
  onRedirecting: () => null,
};

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [devhookUser, setDevhookUser] = useState({});
  const [webhooks, setWebhooks] = useState(null);
  const [channel, setChannel] = useState({});
  const [initialSignup, setInitialSignup] = useState(false);
  const location = useLocation();
  const { signup } = queryString.parse(location.search);
  const history = useHistory();
  const value = {
    devhookUser,
    setDevhookUser,
    webhooks,
    setWebhooks,
    channel,
    setChannel,
    initialSignup,
    setInitialSignup,
  };

  useEffect(() => {
    if (initialSignup) {
      history.push("/finish-signup");
    }
  }, [initialSignup, history]);

  return (
    <Auth0ProviderWithHistory signup={signup}>
      <SocketProvider url={SOCKET_URL}>
        <UserContext.Provider value={value}>
          <div className="flex max-h-screen h-screen bg-devhook-100 text-devhook-900 overflow-hidden">
            <Sidebar
              hideSidebar={initialSignup}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-devhook-100">
              <Header
                hideHeader={initialSignup}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
              <main className="flex-initial content-height">
                <div className="h-full max-h-full px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                  <Switch>
                    <ProtectedRoute
                      path="/"
                      exact
                      component={() => <WebhookList />}
                      onRedirecting={() => <WebhookList authLoading />}
                    />
                    <ProtectedRoute
                      path="/profile"
                      exact
                      component={() => <Profile />}
                      onRedirecting={() => <Profile authLoading />}
                    />
                    <ProtectedRoute
                      path="/:webhook_uid/monitor"
                      exact
                      component={() => <Monitor />}
                      onRedirecting={() => <Monitor authLoading />}
                    />
                    <ProtectedRoute
                      path="/webhooks"
                      exact
                      component={() => <WebhookList />}
                      onRedirecting={() => <WebhookList authLoading />}
                    />
                    <ProtectedRoute
                      path="/webhooks/new"
                      exact
                      component={() => <NewWebhook />}
                      onRedirecting={() => <NewWebhook authLoading />}
                    />
                    <ProtectedRoute
                      path="/:webhook_uid/edit"
                      exact
                      component={() => <EditWebhook />}
                      onRedirecting={() => <EditWebhook authLoading />}
                    />
                    <ProtectedRoute
                      path="/finish-signup"
                      exact
                      component={() => <FinishSignup />}
                      onRedirecting={() => <FinishSignup authLoading />}
                    />
                    <ProtectedRoute component={() => <h1>Page not found</h1>} />
                  </Switch>
                </div>
              </main>
            </div>
          </div>
        </UserContext.Provider>
      </SocketProvider>
    </Auth0ProviderWithHistory>
  );
}

export default App;
