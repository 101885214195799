window.env = window.env || {};

export const AUTH0_AUDIENCE =
  window.env.REACT_APP_AUTH0_AUDIENCE ||
  process.env.REACT_APP_AUTH0_AUDIENCE ||
  "https://devhook.io";

export const API_HOST =
  window.env.REACT_APP_API_HOST ||
  process.env.REACT_APP_API_HOST ||
  "http://localhost:4000";

export const AUTH0_CLIENT_ID =
  window.env.REACT_APP_AUTH0_CLIENT_ID ||
  process.env.REACT_APP_AUTH0_CLIENT_ID ||
  "5ezRUDzlB6Y3IEYNe5VvxapcPFMDdHHC";

export const AUTH0_DOMAIN =
  window.env.REACT_APP_AUTH0_DOMAIN ||
  process.env.REACT_APP_AUTH0_DOMAIN ||
  "devhook.us.auth0.com";

export const SOCKET_URL =
  window.env.REACT_APP_SOCKET_URL ||
  process.env.REACT_APP_SOCKET_URL ||
  process.env.SOCKET_URL ||
  "ws://localhost:4000/socket";
