import React from "react";

export default function HelpInput({
  onChange,
  value,
  label,
  helpText,
  required,
}) {
  return (
    <div className="flex-grow">
      <label
        for="name"
        className="block text-md pl-2 font-medium text-devhook-700"
      >
        {label}
      </label>
      <div className="mt-1 max-w-3xl relative">
        <input
          type="text"
          id="name"
          required={required}
          className="focus:ring-devhook-500 focus:border-devhook-500 block w-full pl-4 pr-8 sm:text-sm border border-devhook-250 rounded-lg shadow-md"
          value={value}
          onChange={onChange}
        />
        <div className="text-sm mt-1 ml-2 text-devhook-800">{helpText}</div>
      </div>
    </div>
  );
}
