import React, { useEffect, useState } from "react";
import { Socket } from "phoenix";
import { useAuth0 } from "@auth0/auth0-react";

export const SocketContext = React.createContext();

export function SocketProvider({ children, url }) {
  const { getAccessTokenSilently } = useAuth0();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    let s;
    getAccessTokenSilently().then((token) => {
      let options = { params: { token } };
      s = new Socket(url, options);
      s.connect();
      setSocket(s);
    });

    return () => {
      s.disconnect();
      setSocket(null);
    };
  }, [url, getAccessTokenSilently]);

  const props = {
    value: {
      socket,
    },
  };

  return React.createElement(SocketContext.Provider, props, children);
}
